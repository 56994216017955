import React, { useCallback, useEffect, useRef, useState } from "react";
import { RootState } from "../store/store";
import {
  pauseRadio,
  playRadio,
  radioMetadataUpdate,
  RADIO_STATE,
  setRadioFeedState,
} from "../store/liveRadio/action";
import { connect } from "react-redux";
import { RadioUtils } from "../utils/radio";
import _has from "lodash/has";
import { notification } from "antd";

const AudioContext =
  typeof window !== "undefined"
    ? (_has(window, "AudioContext") && window["AudioContext"]) ||
      (_has(window, "webkitAudioContext") && window["webkitAudioContext"]) ||
      (_has(window, "mozAudioContext") && window["mozAudioContext"])
    : null;

let sysend = null;
if (typeof window !== "undefined") {
  sysend = require("sysend");
}

const LiveRadioContext = React.createContext(null);

LiveRadioContext.displayName = "LiveRadioContext";

const createAnalyser = (audioEl) => {
  if (typeof window === "undefined" || typeof AudioContext === "undefined") {
    return;
  }

  let ctx;
  try {
    ctx = new AudioContext();
  } catch (e) {
    console.error(e);
    return;
  }
  if (ctx == null) {
    console.info("ctx is null");
    return;
  }
  let analyser: AnalyserNode, audioSrc;

  // sometimes error occurs here on fast reload
  // due to audio element being reused.
  // This fix is sufficent as long as only appearing on fast reload
  try {
    analyser = ctx.createAnalyser();

    audioSrc = ctx.createMediaElementSource(audioEl);
  } catch (e) {
    console.error("Handled, audio element reused", e);
    return null;
  }
  // we have to connect the MediaElementSource with the analyser
  audioSrc.connect(analyser);
  analyser.connect(ctx.destination);
  // we could configure the analyser: e.g. analyser.fftSize (for further infos read the spec)
  analyser.fftSize = 256;

  analyser.smoothingTimeConstant = 0.8;

  return analyser;
};

const LiveAudioWrapper = ({
  children,
  audioSrc,
  playing,
  volume,
  radioMetadataUpdate,
  playRadio,
  pauseRadio,
  setRadioFeedState,
}) => {
  const [analyser, setAnalyser] = useState(null);

  const audioObjectsRef = useRef({
    audio: null as HTMLAudioElement,
  });

  const [waitingForClick, setWaitingForClick] = useState(false);
  const radioUtils = useRef(null as RadioUtils);

  /**
   * Set volume dynamically using this effect
   */
  useEffect(() => {
    if (audioObjectsRef.current.audio == null || !playing || waitingForClick) {
      return;
    }
    audioObjectsRef.current.audio.volume = volume;
  }, [volume, playing, waitingForClick]);

  const audioLoaded = useCallback(() => {
    if (audioObjectsRef.current.audio == null) {
      return;
    }

    audioObjectsRef.current.audio.removeEventListener(
      "loadeddata",
      audioLoaded
    );

    if (analyser == null || waitingForClick) {
      setAnalyser(createAnalyser(audioObjectsRef.current.audio));
    }

    if (playing) {
      audioObjectsRef.current.audio.play();
    }
  }, [playing, analyser, waitingForClick]);

  const createAudioObject = useCallback(() => {
    const audio = new Audio();
    audio.crossOrigin = "anonymous";
    audio.src = audioSrc;
    audio.volume = volume;

    audio.addEventListener("loadeddata", audioLoaded);

    audio.addEventListener("error", function () {
      console.error(audio.error);
    });

    return audio;
  }, [audioSrc, volume, audioLoaded]);

  const onTabRadioPlay = useCallback(() => {
    pauseRadio();
  }, [pauseRadio]);

  const onClickHandler = useCallback(() => {
    if (audioObjectsRef.current.audio == null || !playing || !waitingForClick) {
      return;
    }

    // const { audio } = audioObjects;
    if (analyser != null) {
      audioObjectsRef.current.audio = createAudioObject();
    }
    if (analyser == null) {
      setAnalyser(createAnalyser(audioObjectsRef.current.audio));
    }

    setWaitingForClick(false);
  }, [playing, waitingForClick, createAudioObject, analyser]);

  useEffect(() => {
    radioUtils.current = new RadioUtils();
    radioUtils.current.startWatch();
    radioUtils.current.onRadioMetadataUpdate(radioMetadataUpdate);
    window.addEventListener("click", onClickHandler, true);
    window.addEventListener("touchstart", onClickHandler, true);
    window.addEventListener("touchend", onClickHandler, true);
    sysend.on("radioPlay", onTabRadioPlay);

    return () => {
      if (radioUtils.current != null) {
        radioUtils.current.stopWatch();
        radioUtils.current = null;
      }
      window.removeEventListener("click", onClickHandler, true);
      window.removeEventListener("touchstart", onClickHandler, true);
      window.removeEventListener("touchend", onClickHandler, true);
      sysend.off("radioPlay", onTabRadioPlay);
    };
  }, [
    radioUtils,
    playRadio,
    setRadioFeedState,
    radioMetadataUpdate,
    onClickHandler,
    onTabRadioPlay,
  ]);

  useEffect(() => {
    if (audioSrc == null) {
      return;
    }

    if (audioObjectsRef.current.audio != null) {
      return;
    }

    audioObjectsRef.current.audio = createAudioObject();
  }, [audioSrc, createAudioObject]);

  useEffect(() => {
    return () => {
      const { audio } = audioObjectsRef.current;
      if (audio != null) {
        audio.src = "";
        audio.autoplay = false;
        audio.load();
      }

      audioObjectsRef.current = { audio: null };
    };
  }, []);

  useEffect(() => {
    if (playing) {
      console.log("sysend broadcast");
      sysend.broadcast("radioPlay", { time: +new Date() });
    }
  }, [playing]);

  useEffect(() => {
    // IIFE
    (async () => {
      const { audio } = audioObjectsRef.current;
      if (audio == null || waitingForClick) {
        return;
      }

      if (playing) {
        audio.src = audioSrc;
        // audio.load();

        try {
          await audio.play();
        } catch (e) {
          if (e.name !== "NotAllowedError") {
            return;
          }

          console.error("Waiting for first click to play audio!");
          setWaitingForClick(true);
          setAnalyser(null);
          notification.info({
            message: "Click to Enable Audio",
            description:
              "By default your browser prevents autoplaying of audio till you click or otherwise interact with the page. Please interact with the page to reenable radio audio!",
          });
        }
      } else {
        audio.pause();
        audio.src = "";
        audio.load();
      }
    })();
  }, [playing, waitingForClick, audioSrc]);

  // rendering on server
  // if (!hasMounted) {
  //   return null;
  // }

  return (
    <LiveRadioContext.Provider
      value={{
        audio: audioObjectsRef.current.audio,
        analyser: analyser,
      }}
    >
      {children}
    </LiveRadioContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  playing: state?.liveRadio?.state === RADIO_STATE.PLAYING,
  audioSrc: state?.liveRadio?.metadata?.audioURL,
  volume: state?.liveRadio?.volume,
});

const mapDispatch = {
  playRadio,
  pauseRadio,
  radioMetadataUpdate,
  setRadioFeedState,
};

const connector = connect(mapState, mapDispatch);

export { LiveRadioContext };

export default connector(LiveAudioWrapper);
