import { RadioMetadata } from "../../store/liveRadio/reducer";
import { API_ENDPOINT } from "../general";

export class RadioUtils {
  latestData: unknown = null;
  updateListeners: ((radioMetadata: RadioMetadata) => void)[];

  eventSource: EventSource;

  static _EventSource =
    typeof window !== "undefined" ? window["EventSource"] : null;

  private async getEventSource() {
    if (typeof RadioUtils._EventSource === "undefined") {
      // allowing dynamic require to reduce general package size
      const polyfill = await import("event-source-polyfill");
      RadioUtils._EventSource =
        polyfill.NativeEventSource || polyfill.EventSourcePolyfill;
    } else {
      return RadioUtils._EventSource;
    }
  }

  async startWatch(): Promise<void> {
    if (typeof window === "undefined") {
      // is server... don't start watch!
      return;
    }

    this.updateListeners = [];

    const LocalEventSource = await this.getEventSource();

    // run fetch every 1000 seconds

    this.eventSource = new LocalEventSource(
      API_ENDPOINT + "/purple-radio/radio-data/sse"
    );

    this.eventSource.onmessage = (msg) => {
      let latestData = JSON.parse(msg.data);

      if (latestData == null) {
        latestData = this.latestData;
      } else {
        this.latestData = latestData;
      }

      this.updateListeners.forEach((listener) => listener(latestData));
    };
  }

  onRadioMetadataUpdate(cb: (radioMetadata: RadioMetadata) => void): void {
    this.updateListeners.push(cb);
  }

  stopWatch(): void {
    if (typeof window === "undefined") {
      // is server... no point ending watch
      return;
    }

    this.updateListeners = [];
    if (typeof this.eventSource !== "undefined") {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
}
