import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieBanner: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Confirm"
      containerClasses={"cookie-banner"}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      Hey! We use cookies to enhance the user experience and for analytics
      purposes. By continuing to use our services you are giving us your consent
      to use cookies.{" "}
    </CookieConsent>
  );
};

export default CookieBanner;
