import React from "react";

require("@/styles/header.less");

import Link from "next/link";
import { Menu } from "antd";
import { RootState } from "@/store/store";

import {
  pauseRadio,
  playRadio,
  RADIO_FEED_STATE,
  setRadioFeedState,
} from "@/store/liveRadio/action";
import { connect } from "react-redux";
import { compose } from "redux";

import StrapiProgressiveImage from "./StrapiProgressiveImage";
import { setDJFormVisibility } from "@/store/djMessageForm/action";
import dynamic from "next/dynamic";

const Headroom = dynamic<{ className: string }>(
  () => import("react-headroom"),
  {
    ssr: false,
  }
);

interface NavigationURLItem {
  __typename: "ComponentNavigationMenuList";
  Title: string;
  URL: string;
  Big: boolean;
}
interface NavigationActionItem {
  __typename: "ComponentNavigationActionEntry";
  Title: string;
  Action: "PlayRadio" | "StopRadio" | "OpenMessageDJ" | "CloseMessageDJ";
  Big: boolean;
}

type NavigationItem = NavigationActionItem | NavigationURLItem;

function Header({
  data,
  playRadio,
  pauseRadio,
  setRadioFeedState,
  setDJFormVisibility,
}) {
  const logo = data?.navbarMenu?.Logo;
  return (
    <Headroom className={"top-bar"}>
      <div className="float-left">
        <Link href="/">
          <a>
            {logo == null ? (
              "Purple Radio"
            ) : (
              <StrapiProgressiveImage image={logo} size="logo" />
            )}{" "}
          </a>
        </Link>
      </div>

      {/* todo selected keys */}
      <Menu selectedKeys={null} mode="horizontal" className="nav-menu">
        {(data?.navbarMenu?.MenuItem || []).map((navItem: NavigationItem) => {
          if (navItem.__typename === "ComponentNavigationActionEntry") {
            return (
              <Menu.Item
                key={navItem.Title}
                className={navItem?.Big ? "big-nav-item" : ""}
              >
                {/* TODO switch to Link under certain cirumstances */}
                {
                  <a
                    onClick={() => {
                      switch (navItem.Action) {
                        case "PlayRadio":
                          setRadioFeedState(RADIO_FEED_STATE.SHOWN);
                          playRadio();
                          break;
                        case "StopRadio":
                          pauseRadio();
                          break;
                        case "OpenMessageDJ":
                          setDJFormVisibility(true);
                          break;
                        case "CloseMessageDJ":
                          setDJFormVisibility(false);
                          break;
                        default:
                          console.error(
                            "Unknown navItem action",
                            navItem.Action
                          );
                      }
                    }}
                  >
                    {navItem.Title}
                  </a>
                }
              </Menu.Item>
            );
          }
          if (navItem.__typename === "ComponentNavigationMenuList") {
            return (
              <Menu.Item
                key={navItem.URL}
                className={navItem?.Big ? "big-nav-item" : ""}
              >
                {/* TODO switch to Link under certain cirumstances */}
                {
                  <Link href={navItem.URL} key={navItem.URL}>
                    <a>{navItem.Title}</a>
                  </Link>
                }
              </Menu.Item>
            );
          }
        })}
      </Menu>
      {/* <nav>
        <ul>
          {data.navbarMenu.MenuList.map((navItem: NavigationItem) => {
            return (
              <li key={navItem.URL}>
                <a href={navItem.URL} key={navItem.URL}>
                  {navItem.Title}
                </a>
              </li>
            );
          })}
        </ul>
      </nav> */}
    </Headroom>
  );
}

const mapState = (state: RootState) => ({});

const mapDispatch = {
  playRadio,
  pauseRadio,
  setRadioFeedState,
  setDJFormVisibility,
};

const connector = connect(mapState, mapDispatch);

export default compose(connector)(Header);
