import React from "react";
import ProgressiveImage from "react-progressive-image";
import { StrapiImage } from "../typings/strapi";

/**
 * @See purpleRadioCustom.js
 */
const imageTypes = [
  "8k",
  "4k",
  "1440p",
  "1080p",
  "720p",
  "480p",
  "360p",
  "logo",
] as const;

export const GRAPHQL_STRAPI_IMAGE = `\
width,
height,
name,
formats,
url,
alternativeText,
caption,` as const;

const StrapiProgressiveImage: React.FC<{
  image: StrapiImage;
  [option: string]: unknown;
  size?: typeof imageTypes[number];
}> = ({ image, size, ...options }) => {
  if (image == null) {
    return null;
  }

  const origFormat = image?.formats?.["orig"];
  const imageFormat = imageTypes
    .map((type) => image?.formats?.[type])
    .filter((x) => x != null);

  return (
    <ProgressiveImage
      srcSetData={{
        srcSet: [
          ...imageFormat.map((format) => `${format.url} ${format.width}w`),
          `${origFormat?.url} ${origFormat?.width}w`,
        ].join(", "),
        sizes: null,
      }}
      src={
        size != null
          ? imageFormat?.[imageTypes.indexOf(size)]?.url
          : origFormat?.url
      }
      placeholder={
        (size != null
          ? image?.formats?.[size + "_qlif"]?.url
          : image?.formats?.["qlif"]?.url) || image?.formats?.["qlif"]?.url
      }
    >
      {(src: string, loading: boolean, srcSetData: { srcSet: string }) => (
        <img
          src={src}
          srcSet={srcSetData.srcSet}
          {...options}
          style={{ filter: loading ? "blur(5px)" : "none" }}
          alt={image.alternativeText}
        />
      )}
    </ProgressiveImage>
  );
};

export default StrapiProgressiveImage;
