import Head from "next/head";
import Header from "./Header";
import LiveAudioWrapper from "@/components/LiveAudioWrapper";
import { Context as ResponsiveContext } from "react-responsive";

import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import React from "react";
import { RootState } from "@/store/store";

import { connect } from "react-redux";

import { compose } from "redux";
import dynamic from "next/dynamic";
import CookieBanner from "./CookieBanner";

import { Layout as L } from "antd";

import { MotionConfig } from "framer-motion";
import { isChristmas } from "@/utils/general";
import { RADIO_STATE } from "@/store/liveRadio/action";

const dev = process.env.NODE_ENV !== "production";

const RadioFeed = dynamic(() => import("./RadioFeed"), { ssr: false });
const Snowfall = dynamic(() => import("react-snowfall"), { ssr: false });
const AdminBar = dynamic(() => import("./AdminBar"), { ssr: false });
const SocialMediaIcons = dynamic(() => import("./SocialMediaIcons"));
const DJMessageDrawer = dynamic<{
  welcomeMessage: string;
  thankYouMessage: string;
}>(() => import("../components/DJMessageDrawer"), {
  ssr: false,
});

const { Content, Footer } = L;

export const LAYOUT_QUERY = `
	configuration{
    SEO {
      Description,
      TwitterProfile,
      Keywords
    },
    SocialMediaLinks {
      TwitterLink,
      InstagramLink,
      YoutubeLink,
      FacebookLink
    }
  }
  #header...
  navbarMenu {
    Logo {
      alternativeText
      caption
      url,
      formats
    }
    MenuItem {
      __typename
      ... on ComponentNavigationMenuList {
        Title,
        URL,
        Big
      }
      ... on ComponentNavigationActionEntry {
        Title,
        Action,
        Big
      }
    }
  }
  #messageDJ
  messageTheDj {
    WelcomeMessage,
    ThankYouMessage
  }
`;

const Layout: React.FC<{ data: any; title: string; playing: boolean }> = ({
  data,
  children,
  title,
  playing,
}) => {
  const router = useRouter();
  const role = useSelector<RootState>(({ auth }) => auth.role) as string;

  const { FacebookLink, YoutubeLink, TwitterLink, InstagramLink } =
    data?.configuration?.SocialMediaLinks || {};

  return (
    <ResponsiveContext.Provider
      value={typeof window === "undefined" ? { width: 1900 } : undefined}
    >
      <MotionConfig>
        <LiveAudioWrapper>
          <>
            <Head>
              <title>
                {playing ? "[🎧] " : ""}
                {title || "Purple Radio"}
              </title>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              <link rel="manifest" href="/site.webmanifest" />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />

              <meta
                name="description"
                content={data?.configuration?.SEO?.Description}
              />
              {/* <meta name="image" content={seo.image} /> */}
              {/* <link rel="canonical" href={seo.url} /> */}
              {/* <meta property="og:url" content={seo.url} /> */}
              <meta property="og:type" content="article" />
              <meta
                name="keywords"
                content={data?.configuration?.SEO?.Keywords}
              />

              {/* we need better way of setting title TODO */}
              <title>Purple Radio</title>
              <meta property="og:title" content={"Purple Radio"} />
              <meta name="twitter:title" content={"Purple Radio"} />

              <meta
                property="og:description"
                content={data?.configuration?.SEO?.Description}
              />
              {/* <meta property="og:image" content={seo.image} /> */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:creator"
                content={data?.configuration?.SEO?.TwitterProfile}
              />
              <meta
                name="twitter:site"
                content={data?.configuration?.SEO?.TwitterProfile}
              />

              <meta
                name="twitter:description"
                content={data?.configuration?.SEO?.Description}
              />
              {/* <meta name="twitter:image" content={seo.image} /> */}
            </Head>
            {role && role !== "guest" && <AdminBar />}
            <L className="layout">
              <Header data={data} />

              <DJMessageDrawer
                welcomeMessage={data?.messageTheDj?.WelcomeMessage || ""}
                thankYouMessage={data?.messageTheDj?.ThankYouMessage || ""}
              />
              <RadioFeed />
              <div className="snowfall">
                {isChristmas() ? <Snowfall color={"white"} /> : null}
              </div>
              <Content key={router.route} className={"content"}>
                {children}
              </Content>
              <CookieBanner />

              <Footer style={{ textAlign: "center" }} className={"footer"}>
                <div className={"footer-media"}>
                  <SocialMediaIcons
                    facebookLink={FacebookLink}
                    twitterLink={TwitterLink}
                    youtubeLink={YoutubeLink}
                    instagramLink={InstagramLink}
                  />
                </div>
                <span>Purple Radio is a Durham SU student group whose details are: Durham Students’ Union (also known as Durham SU or DSU) is a charity registered in England and Wales (1145400) and a company limited by guarantee (07689815), and its principal address is Dunelm House, New Elvet, DURHAM, County Durham, DH1 3AN</span>
                <br/>
                Developed by <a href="https://georgep.co.uk/">George P</a> and{" "}
                <a href="https://simonjjlake.com/">
                  Simon L
                </a> —
                Purple Radio ©2022 — Designed by{" "}
                <a href="https://simonjjlake.com/">
                  Simon L
                </a>
              </Footer>
            </L>
          </>
        </LiveAudioWrapper>
      </MotionConfig>
    </ResponsiveContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  playing: state.liveRadio.state === RADIO_STATE.PLAYING,
});

const mapDispatch = {};

const reduxConnector = connect(mapState, mapDispatch);

const prodOnlyHocs = !dev ? [] : [];

export default compose(
  reduxConnector,
  ...prodOnlyHocs
)(Layout) as React.FunctionComponent<{
  data: Record<string, unknown>;
  title?: string;
}>;
